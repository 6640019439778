



















export default {
  props: ['current', 'max', 'faIcon', 'primaryColor', 'altText', 'showMinimalVersion'],

  computed: {
    factor(): number {
      return Math.min(1, ((this as any).current) / ((this as any).max));
    },

    percent(): string {
      return (this.factor as unknown as number * 100) + '%';
    },
  },
};











































































































































































































import Vue from 'vue';
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import CharacterList from '../components/smart/CharacterList.vue';
import WeaponGrid from '../components/smart/WeaponGrid.vue';
import BigButton from '../components/BigButton.vue';
import WeaponIcon from '../components/WeaponIcon.vue';
import Hint from '../components/Hint.vue';
import NftIcon from '@/components/NftIcon.vue';
import NftList, { NftIdType } from '@/components/smart/NftList.vue';
import CurrencyConverter from '../components/CurrencyConverter.vue';
import { GetTotalMultiplierForTrait, IWeapon } from '@/interfaces/Weapon';
import { CharacterPower, IRaidState, IState } from '@/interfaces';
import { getBossArt } from '@/raid-boss-art-placeholder';
import { traitNumberToName } from '@/contract-models';
import { fromWeiEther } from '@/utils/common';
import { staminaToHours } from '@/utils/date-time';
import { RaidRewards, Weapon, Junk, Keybox, DustLb, Dust4b, Dust5b, BonusXp } from '@/interfaces/RaidRewards';


interface RaidMappedActions {
  fetchRaidState(): Promise<void>;
  joinRaid(payload: { characterId: string, weaponId: string}): Promise<void>;
  fetchRaidRewards(payload: {startIndex: number, endIndex: string }): Promise<string[]>;
  claimRaidRewards(payload: { rewardIndex: string }): Promise<RaidRewards>;
  fetchRaidingCharacters(): Promise<string[]>;
  fetchRaidingWeapons(): Promise<string[]>;
  fetchIsRaidStarted(): Promise<boolean>;
  fetchHaveEnoughEnergy(payload: { characterID: string, weaponID: string }): Promise<boolean>;
  fetchIsCharacterRaiding(payload: { characterID: string }): Promise<boolean>;
  fetchIsWeaponRaiding(payload: { weaponID: string }): Promise<boolean>;
  fetchCharacters(payload: { characterIds: (string | number)[]}): Promise<void>;
}

interface RaidMappedMutations {
  setCurrentCharacter(state: IState, characterId: number): void;
}

interface RaidMappedGetters {
  getRaidState(): IRaidState;
}

let interval: number;

const dragonNames = [
  'Fudbringer',
  'HODL Lord',
  'Fchs Eater',
  'Chain Congester',
  'Swap Guardian',
  'Blade Hoarder',
  'Centralizer',
  'Exchange Tormentor',
  'Eater of Stakes'
];

const bossImages = [
  '../assets/CB_Hellborn Brute.gif',
  '../assets/CB_Hellborn Executioner.gif',
  '../assets/CB_Hellborn Marauder.gif',
  '../assets/CB_Hellborn Overlord.gif',
  '../assets/CB_Hellborn Shaman.gif',
];

export default Vue.extend({
  data() {
    return {
      selectedWeaponId: '',
      raidIndex: '',
      bossName: '',
      raiderCount: '',
      totalPower: '',
      expectedFinishTime: '',
      xpReward: '',
      staminaCost: '',
      durabilityCost: '',
      joinCost: '',
      raidStatus: '',
      bossPower: '',
      bossTrait: '',
      accountPower: '',
      rewardsRaidId: '',
      rewardIndexes: [] as string[],
      rewards: [] as NftIdType[],
      spin: false,
      participatingCharacters: [] as string[],
      participatingWeapons: [] as string[],
      bonuxXpCharacterNames: [] as string[],
      bonuxXpAmounts: [] as string[],
    };
  },

  computed: {
    ...mapState(['characters', 'maxStamina', 'currentCharacterId', 'ownedCharacterIds', 'defaultAccount']),
    ...mapGetters(['ownCharacters', 'ownWeapons', 'currentCharacter',
      'currentCharacterStamina', 'getWeaponDurability', 'contracts', 'getCharacterName']),

    claimButtonActive(): boolean {
      return this.rewardIndexes !== null && this.rewardIndexes.length > 0;
    },

    currentMultiplier(): string {
      if(!this.selectedWeaponId) return '0';
      const currentWeapon = this.ownWeapons.find((weapon: IWeapon) => weapon.id === +this.selectedWeaponId);
      if(!currentWeapon) return '0';
      return GetTotalMultiplierForTrait(currentWeapon, this.currentCharacter.trait).toFixed(2);
    },

    currentCharacterPower(): number {
      if(!this.currentCharacter) return 0;
      return CharacterPower(this.currentCharacter.level);
    },

    getSelectedWeapon(): IWeapon {
      return this.ownWeapons.find((weapon: IWeapon) => weapon.id === +this.selectedWeaponId);
    },

    formatStaminaHours(): string {
      return staminaToHours(+this.staminaCost).toFixed(1);
    },

    formattedWinChance(): string {
      return `${this.calculateWinChance()}%`;
    }
  },

  methods: {
    getBossArt,
    traitNumberToName,
    ...(mapActions([
      'fetchRaidState',
      'joinRaid',
      'fetchRaidRewards',
      'claimRaidRewards',
      'fetchRaidingCharacters',
      'fetchRaidingWeapons',
      'fetchIsRaidStarted',
      'fetchHaveEnoughEnergy',
      'fetchIsCharacterRaiding',
      'fetchIsWeaponRaiding',
      'fetchCharacters'
    ]) as RaidMappedActions),
    ...(mapMutations([
      'setCurrentCharacter'
    ]) as RaidMappedMutations),
    ...(mapGetters([
      'getRaidState',
    ]) as RaidMappedGetters),

    calculateWinChance(): string {
      return (Math.min(Math.max(+this.totalPower / +this.bossPower / 2 * 100, 0), 99.99)).toFixed(2);
    },

    calculateProgressBarColor(): string {
      if(+this.calculateWinChance() < 30){
        return '#ccae4f';
      } else if (+this.calculateWinChance() < 70){
        return 'blue';
      } else {
        return 'green';
      }
    },

    calculatePlayersProgressBarWidth(): string {
      return `${Math.round(+this.calculateWinChance())}%`;
    },

    calculateBossProgressBarWidth(): string {
      return `${Math.round(100 - +this.calculateWinChance())}%`;
    },

    convertWeiToFchs(wei: string): string {
      return fromWeiEther(wei);
    },

    weaponHasDurability(id: number): boolean{
      return this.getWeaponDurability(id) > 0;
    },

    async joinRaidMethod(): Promise<void> {
      if (!this.selectedWeaponId || !this.currentCharacterId) {
        (this as any).$dialog.notify.error('Check Character and Weapon Selection and try again...');
        return;
      }

      const isRaidStarted = await this.isRaidStarted();
      if(!isRaidStarted) {
        (this as any).$dialog.notify.error('Raid has not started yet...');
        return;
      }
      const isCharacterRaiding = await this.isCharacterAlreadyRaiding(this.currentCharacterId);
      if(isCharacterRaiding) {
        (this as any).$dialog.notify.error('Selected character is locked in the raid already...');
        return;
      }
      const isWeaponRaiding = await this.isWeaponAlreadyRaiding(this.selectedWeaponId);
      if(isWeaponRaiding) {
        (this as any).$dialog.notify.error('Selected weapon is locked in the raid already...');
        return;
      }
      const haveEnoughEnergy = await this.haveEnoughEnergy(this.currentCharacterId, this.selectedWeaponId);
      if(!haveEnoughEnergy) {
        (this as any).$dialog.notify.error('Not enough stamina or durability...');
        return;
      }

      try {
        await this.joinRaid({ characterId: this.currentCharacterId, weaponId: this.selectedWeaponId});
        this.selectedWeaponId = '';
      } catch (e) {
        console.error(e);
        (this as any).$dialog.notify.error('Whoops...');
      }

      await this.getParticipatingCharacters();
      await this.getParticipatingWeapons();
    },

    async getParticipatingCharacters(): Promise<void> {
      // gets the list of this player's raid locked characters
      // TODO store these?
      this.participatingCharacters = await this.fetchRaidingCharacters();
    },

    async getParticipatingWeapons(): Promise<void> {
      // gets the list of this player's raid locked weapons
      // TODO store these?
      this.participatingWeapons = await this.fetchRaidingWeapons();
    },

    async isCharacterAlreadyRaiding(characterID: string): Promise<boolean> {
      return await this.fetchIsCharacterRaiding({
        characterID
      });
    },

    async isWeaponAlreadyRaiding(weaponID: string): Promise<boolean> {
      return await this.fetchIsWeaponRaiding({
        weaponID
      });
    },

    async isRaidStarted(): Promise<boolean> {
      return await this.fetchIsRaidStarted();
    },

    async haveEnoughEnergy(characterID: string, weaponID: string): Promise<boolean>{
      return await this.fetchHaveEnoughEnergy({
        characterID,
        weaponID
      });
    },

    async getRewardIndexes(): Promise<void> {
      if(!this.raidIndex)
        return;
      let startIndex = +this.raidIndex-21; // one week worth
      if(startIndex < 0)
        startIndex = 0;
      const endIndex = this.raidIndex;

      this.rewardIndexes = await this.fetchRaidRewards({
        startIndex,
        endIndex
      });
    },

    promptRewardClaim(): void {
      // should offer a popup here to pick which index to claim
      // if only one index, then claim instantly
      if(this.rewardIndexes !== null && this.rewardIndexes.length > 0) {
        if(this.rewardIndexes.length === 1) {
          this.claimRewardIndex(this.rewardIndexes[0]);
        }
        else {
          (this as any).$bvModal.show('rewardsRaidPicker');
        }
      }
    },

    async claimRewardIndex(rewardIndex: string): Promise<void> {
      this.bonuxXpCharacterNames = [];
      this.bonuxXpAmounts = [];
      const result = await this.claimRaidRewards({
        rewardIndex
      });

      const nfts: NftIdType[] = [];
      if(result.weapons) {
        result.weapons.forEach((x: Weapon) => {
          nfts.push({ type: 'weapon', id: x.tokenID });
        });
      }
      if(result.junks) {
        result.junks.forEach((x: Junk) => {
          nfts.push({ type: 'junk', id: x.tokenID });
        });
      }
      if(result.keyboxes) {
        result.keyboxes.forEach((x: Keybox) => {
          nfts.push({ type: 'keybox', id: x.tokenID });
        });
      }
      if(result.dustLb) {
        result.dustLb.forEach((x: DustLb) => {
          nfts.push({ type: 'dustLb', id: 0, amount: x.amount });
        });
      }
      if(result.dust4b) {
        result.dust4b.forEach((x: Dust4b) => {
          nfts.push({ type: 'dust4b', id: 0, amount: x.amount });
        });
      }
      if(result.dust5b) {
        result.dust5b.forEach((x: Dust5b) => {
          nfts.push({ type: 'dust5b', id: 0, amount: x.amount });
        });
      }
      if(result.bonusXp) {
        result.bonusXp.forEach((x: BonusXp) => {
          this.bonuxXpCharacterNames.push(this.getCharacterName(x.charID));
          this.bonuxXpAmounts.push(x.amount);
        });
      }

      this.rewards = nfts;
      this.spin = true;
      (this as any).$bvModal.show('rewardsModal');
      setTimeout(() => {
        this.spin = false;
      }, 10000);

      await this.fetchCharacters(this.ownedCharacterIds);
    },

    getBossName(): string {
      return dragonNames[+this.raidIndex % dragonNames.length];
    },

    getBossImage(): string {
      return bossImages[+this.raidIndex % bossImages.length];
    },

    processRaidData(): void {
      const raidData = this.getRaidState();
      this.raidIndex = raidData.index;
      this.bossName = this.getBossName();
      this.raiderCount = raidData.raiderCount;
      this.totalPower = raidData.playerPower;
      this.expectedFinishTime = new Date(+raidData.expectedFinishTime * 1000).toLocaleString();
      this.xpReward = raidData.xpReward;
      this.staminaCost = raidData.staminaCost;
      this.durabilityCost = raidData.durabilityCost;
      this.joinCost = raidData.joinFchs;
      this.raidStatus = raidData.status ? 'Preparation' : 'Finished';
      this.bossPower = raidData.bossPower;
      this.bossTrait = raidData.bossTrait;
      this.accountPower = raidData.accountPower;
    }
  },

  async mounted() {
    const refreshRaidData = async () => {
      await (this as any).getRewardIndexes();
      await (this as any).fetchRaidState();
      (this as any).processRaidData();
      await (this as any).getParticipatingCharacters();
      await (this as any).getParticipatingWeapons();
    };
    await refreshRaidData();
    interval = window.setInterval(async () => {
      await refreshRaidData();
    }, 3000);
  },

  beforeDestroy() {
    clearInterval(interval);
  },

  components: {
    CurrencyConverter,
    BigButton,
    CharacterList,
    WeaponGrid,
    WeaponIcon,
    Hint,
    NftIcon,
    NftList
  },
});










































































import Vue from 'vue';
import { Accessors } from 'vue/types/options';
import { mapActions, mapGetters, mapState } from 'vuex';
import BigNumber from 'bignumber.js';
import { RequiredXp } from '../../interfaces';
import { ICharacter } from '@/interfaces';
import { toBN, fromWeiEther } from '../../utils/common';
import { secondsToDDHHMMSS } from '../../utils/date-time';
import { getCleanName } from '../../rename-censor';

interface StoreMappedState {
  fchsRewards: string;
  xpRewards: Record<string, string>;
  ownedCharacterIds: string[];
  directStakeBonusPercent: number;
}

interface StoreMappedGetters {
  ownCharacters: ICharacter[];
  currentCharacter: ICharacter | null;
  maxRewardsClaimTaxAsFactorBN: BigNumber;
  rewardsClaimTaxAsFactorBN: BigNumber;
  getCharacterName(id: number): string;
}

enum ClaimStage {
  WaxBridge = 0,
  Stake = 1,
  Claim = 2
}

interface StoreMappedActions {
  claimTokenRewards(): Promise<void>;
  claimXpRewards(): Promise<void>;
  fetchRemainingTokenClaimAmountPreTax(): Promise<string>;
}

export default Vue.extend({
  data() {
    return {
      ClaimStage,
      remainingTokenClaimAmountPreTax: '0'
    };
  },

  computed: {
    ...(mapState(['fchsRewards', 'xpRewards', 'ownedCharacterIds', 'directStakeBonusPercent']) as Accessors<StoreMappedState>),
    ...(mapGetters([
      'ownCharacters', 'currentCharacter', 'maxRewardsClaimTaxAsFactorBN', 'rewardsClaimTaxAsFactorBN', 'getCharacterName'
    ]) as Accessors<StoreMappedGetters>),

    formattedFchsReward(): string {
      const fchsRewards = fromWeiEther(this.fchsRewards);
      return `${toBN(fchsRewards).toFixed(4)}`;
    },

    formattedTaxAmount(): string {
      const fchsRewards = fromWeiEther((parseFloat(this.fchsRewards)* parseFloat(String(this.rewardsClaimTaxAsFactorBN))).toString());
      return `${toBN(fchsRewards).toFixed(4)}`;
    },

    formattedBonusLost(): string {
      const fchsLost = fromWeiEther((parseFloat(this.fchsRewards)*this.directStakeBonusPercent/100).toString());
      return `${toBN(fchsLost).toFixed(4)}`;
    },

    formattedRemainingClaimableFchs(): string {
      const fchsClaimable = fromWeiEther(this.remainingTokenClaimAmountPreTax);
      return `${toBN(fchsClaimable).toFixed(4)}`;
    },

    formattedRewardsClaimTax(): string {
      const frac =
        this.fchsRewards === '0'
          ? this.maxRewardsClaimTaxAsFactorBN
          : this.rewardsClaimTaxAsFactorBN;

      return `${frac.multipliedBy(100).decimalPlaces(0, BigNumber.ROUND_HALF_UP)}%`;
    },

    getTaxTimerNextTick(): string {
      let frac: BigNumber;

      // if has no fchs rewards do not display timer next tick.
      if (this.fchsRewards === '0') {
        return '';
      } else {
        frac = this.rewardsClaimTaxAsFactorBN;
      }

      // get 2 decimal values
      const decVal = toBN(frac.multipliedBy(100).decimalPlaces(2).toString().split('.')[1]);
      // convert to seconds
      const toSec = decVal.dividedBy(100).multipliedBy(24).multipliedBy(60).multipliedBy(60);
      // return message
      return ` Next -1% reduction happens in ${secondsToDDHHMMSS(toSec.toNumber())}.`;
    },

    fchsRewardNumber(): number {
      return toBN(fromWeiEther(this.fchsRewards)).toNumber();
    },

    withdrawalInfoText(): string {
      if(this.fchsRewardNumber >= 1) {
        return `You can withdraw max 10% of amount over 1 FCHS or 2 days worth of fights per day (whatever is greater).
          Remaining claimable amount today: ${this.formattedRemainingClaimableFchs}`;
      }
      return `You can withdraw 1 day worth of fights per day.
        Remaining claimable amount today: ${this.formattedRemainingClaimableFchs}`;
    },

    xpRewardsForOwnedCharacters(): string[] {
      return this.ownedCharacterIds.map(charaId => this.xpRewards[charaId] || '0');
    },

    formattedXpRewards(): string {
      return this.xpRewardsForOwnedCharacters.map((xp, i) => {
        const currentCharacter = this.currentCharacter || { id: null };
        if(!this.ownCharacters[i]) return `${xp}`;
        return  `${this.ownCharacters[i].id === currentCharacter.id ? '<b>' : ''}` +
                `${(this.ownCharacters[i].xp + this.xpRewards[this.ownCharacters[i].id]) as any > RequiredXp(this.ownCharacters[i].level) ? '<u>' : ''}` +
                `${(this.getCleanCharacterName(this.ownCharacters[i].id))} ${xp}` +
                `${(this.ownCharacters[i].xp + this.xpRewards[this.ownCharacters[i].id]) as any > RequiredXp(this.ownCharacters[i].level) ? '</u>' : ''}` +
                `${this.ownCharacters[i].id === currentCharacter.id ? '</b>' : ''}`;
      }).join(', ');
    },

    canClaimTokens(): boolean {
      if(toBN(this.fchsRewards).lte(0) || toBN(this.remainingTokenClaimAmountPreTax).lte(0)) {
        return false;
      }

      return true;
    },

    canClaimXp(): boolean {
      const allXpsAreZeroOrLess = this.xpRewardsForOwnedCharacters.every(xp => toBN(xp).lte(0));
      if(allXpsAreZeroOrLess) {
        return false;
      }

      return true;
    }
  },

  methods: {
    ...(mapActions(['addMoreFchs', 'claimTokenRewards', 'claimXpRewards', 'fetchRemainingTokenClaimAmountPreTax']) as StoreMappedActions),

    async onClaimTokens() {
      if(this.canClaimTokens) {
        await this.claimTokenRewards();
      }
    },

    async onClaimXp() {
      if(this.canClaimXp) {
        await this.claimXpRewards();
      }
    },

    async claimFchs(stage: ClaimStage) {
      if(stage === ClaimStage.WaxBridge) {
        (this.$refs['need-gas-modal'] as any).show();
      }
      if(stage === ClaimStage.Stake) {
        (this.$refs['stake-suggestion-modal'] as any).show();
      }
      if(stage === ClaimStage.Claim) {
        (this.$refs['stake-suggestion-modal'] as any).hide();
        (this.$refs['claim-confirmation-modal'] as any).show();
      }
      await this.getRemainingTokenClaimAmountPreTax();
    },

    async getRemainingTokenClaimAmountPreTax() {
      this.remainingTokenClaimAmountPreTax = await this.fetchRemainingTokenClaimAmountPreTax();
    },

    getCleanCharacterName(id: number): string {
      return getCleanName(this.getCharacterName(id));
    }
  },

  async mounted() {
    setInterval(async () => await this.getRemainingTokenClaimAmountPreTax(), 3000);
  }
});

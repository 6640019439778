import { render, staticRenderFns } from "./Market.vue?vue&type=template&id=5eaa1d0d&scoped=true&"
import script from "./Market.vue?vue&type=script&lang=ts&"
export * from "./Market.vue?vue&type=script&lang=ts&"
import style0 from "./Market.vue?vue&type=style&index=0&id=5eaa1d0d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5eaa1d0d",
  null
  
)

export default component.exports
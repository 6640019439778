











import Vue from 'vue';
import { PropType } from 'vue/types/options';

export interface NftOption {
  name: string;
  amount: number;
  handler: (id: number | string) => any;
  hasDefaultOption?: boolean;
}

export default Vue.extend({
  props: {
    options: {
      type: Array as PropType<NftOption[]>,
      default() {
        return [];
      },
    },
    nftId: {
      type: Number,
      default: null
    }
  },

  methods: {
    isDisabled(option: NftOption): boolean {
      return !option.hasDefaultOption && +option.amount === 0;
    }
  }
});

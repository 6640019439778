



































































import Events from '../events';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import BigNumber from 'bignumber.js';
import { Accessors } from 'vue/types/options';
import Vue from 'vue';
import { toBN, fromWeiEther } from '../utils/common';
import { getConfigValue } from '@/contracts';
import config from '../../app-config.json';

interface StoreMappedState {
  fchsRewards: string;
  directStakeBonusPercent: number;
}

interface StoreMappedActions {
  claimTokenRewards(): Promise<void>;
  setUpContracts(): Promise<void>;
  initialize(): Promise<void>;
  configureMetaMask(networkId: number): Promise<void>;
}
interface Data {
  showGraphics: boolean;
  hideRewards: boolean;
  hideAdvanced: boolean;
  hideWalletWarning: boolean;
  showFchsInUsd: boolean;
  showCosmetics: boolean;
  fightMultiplier: number;
  currentChain: string;
  supportedChains: string[];
}

interface StoreMappedGetters {
  rewardsClaimTaxAsFactorBN: BigNumber;
  maxRewardsClaimTaxAsFactorBN: BigNumber;
}

enum ClaimStage {
  WaxBridge = 0,
  Stake = 1,
  Claim = 2,
}

export default Vue.extend({
  created() {
    this.showGraphics = localStorage.getItem('useGraphics') === 'true';
    this.hideRewards = localStorage.getItem('hideRewards') === 'true';
    this.hideAdvanced = localStorage.getItem('hideAdvanced') === 'true';
    this.hideWalletWarning = localStorage.getItem('hideWalletWarning') === 'true';
    this.showFchsInUsd = localStorage.getItem('showFchsInUsd') === 'true';
    if(!localStorage.getItem('showCosmetics')) {
      localStorage.setItem('showCosmetics', 'true');
    }
    this.showCosmetics = localStorage.getItem('showCosmetics') !== 'false';
    this.fightMultiplier = Number(localStorage.getItem('fightMultiplier'));
    this.currentChain = localStorage.getItem('currentChain') || 'HECO';
    this.supportedChains = config.supportedChains;
  },
  data() {
    return {
      showGraphics: false,
      hideRewards: false,
      hideAdvanced: false,
      hideWalletWarning: false,
      showFchsInUsd: false,
      showCosmetics: true,
      fightMultiplier: 1,
      currentChain: 'HECO',
      checked: false,
      ClaimStage,
      supportedChains: []
    } as Data;
  },

  computed: {
    ...(mapState(['fchsRewards', 'directStakeBonusPercent']) as Accessors<StoreMappedState>),
    ...(mapGetters(['rewardsClaimTaxAsFactorBN', 'maxRewardsClaimTaxAsFactorBN']) as Accessors<StoreMappedGetters>),

    formattedFchsReward(): string {
      const fchsRewards = fromWeiEther(this.fchsRewards);
      return `${toBN(fchsRewards).toFixed(4)}`;
    },
    formattedTaxAmount(): string {
      const fchsRewards = fromWeiEther((parseFloat(this.fchsRewards)* parseFloat(String(this.rewardsClaimTaxAsFactorBN))).toString());
      return `${toBN(fchsRewards).toFixed(4)}`;
    },
    formattedRewardsClaimTax(): string {
      const frac = this.fchsRewards === '0' ? this.maxRewardsClaimTaxAsFactorBN : this.rewardsClaimTaxAsFactorBN;

      return `${frac.multipliedBy(100).decimalPlaces(0, BigNumber.ROUND_HALF_UP)}%`;
    },
    formattedBonusLost(): string {
      const fchsLost = fromWeiEther((parseFloat(this.fchsRewards)*this.directStakeBonusPercent/100).toString());
      return `${toBN(fchsLost).toFixed(4)}`;
    },
    canClaimTokens(): boolean {
      if (toBN(this.fchsRewards).lte(0)) {
        return false;
      }
      return true;
    },
  },

  methods: {
    ...(mapActions(['claimTokenRewards','setUpContracts','initialize','configureMetaMask']) as StoreMappedActions),
    ...mapMutations(['setNetworkId']),
    toggleGraphics() {
      this.showGraphics = !this.showGraphics;
      if (this.showGraphics) localStorage.setItem('useGraphics', 'true');
      else localStorage.setItem('useGraphics', 'false');

      Events.$emit('setting:useGraphics', { value: this.showGraphics });
    },

    toggleRewards() {
      this.hideRewards = !this.hideRewards;
      if (this.hideRewards) localStorage.setItem('hideRewards', 'true');
      else localStorage.setItem('hideRewards', 'false');

      Events.$emit('setting:hideRewards', { value: this.hideRewards });
    },

    toggleAdvanced() {
      this.hideAdvanced = !this.hideAdvanced;
      if (this.hideAdvanced) localStorage.setItem('hideAdvanced', 'true');
      else localStorage.setItem('hideAdvanced', 'false');

      Events.$emit('setting:hideAdvanced', { value: this.hideAdvanced });
    },
    async onClaimTokens() {
      if (this.canClaimTokens) {
        await this.claimTokenRewards();
      }
    },
    async claimFchs(stage: ClaimStage) {
      if (stage === ClaimStage.WaxBridge) {
        (this.$refs['need-gas-modal'] as any).show();
      }
      if (stage === ClaimStage.Stake) {
        (this.$refs['stake-suggestion-modal'] as any).show();
      }
      if (stage === ClaimStage.Claim) {
        (this.$refs['stake-suggestion-modal'] as any).hide();
        (this.$refs['claim-confirmation-modal'] as any).show();
      }
    },

    toggleHideWalletWarning() {
      this.hideWalletWarning = !this.hideWalletWarning;
      if (this.hideWalletWarning) localStorage.setItem('hideWalletWarning', 'true');
      else localStorage.setItem('hideWalletWarning', 'false');

      Events.$emit('setting:hideWalletWarning', { value: this.hideWalletWarning });
    },

    toggleShowFchsInUsd() {
      this.showFchsInUsd = !this.showFchsInUsd;
      if (this.showFchsInUsd) localStorage.setItem('showFchsInUsd', 'true');
      else localStorage.setItem('showFchsInUsd', 'false');

      Events.$emit('setting:showFchsInUsd', { value: this.showFchsInUsd });
    },

    toggleShowCosmetics() {
      this.showCosmetics = !this.showCosmetics;
      if (this.showCosmetics) localStorage.setItem('showCosmetics', 'true');
      else localStorage.setItem('showCosmetics', 'false');

      Events.$emit('setting:showCosmetics', { value: this.showCosmetics });
    },

    setFightMultiplier() {
      localStorage.setItem('fightMultiplier', this.fightMultiplier.toString());

      Events.$emit('setting:fightMultiplier', { value: this.fightMultiplier });
    },

    async setCurrentChain() {
      localStorage.setItem('currentChain', this.currentChain);
      Events.$emit('setting:currentChain', { value: this.currentChain });
      await this.configureMetaMask(+getConfigValue('VUE_APP_NETWORK_ID'));
    },
  },
});

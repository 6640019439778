import BigNumber from 'bignumber.js';
import { Web3JsCallOptions, Web3JsAbiCall, Web3JsSendOptions } from '../../abi-common';
import { Contract, Contracts } from './interfaces';

export type SWordsAlias = NonNullable<Contracts['SWords']>;
export type NFTMarketAlias = NonNullable<Contracts['NFTMarket']>;

type MethodsFunction<T extends Contract<unknown>> = (contract: T['methods']) => Web3JsAbiCall<string>;
type SWordsMethodsFunction = MethodsFunction<SWordsAlias>;

export async function getFeeInFchsFromUsdFromAnyContract<T extends Contract<unknown>>(
  sWordsContract: SWordsAlias,
  feeContract: T,
  opts: Web3JsCallOptions,
  fn: MethodsFunction<T>
): Promise<string> {
  const feeInUsd = await fn(feeContract.methods).call(opts);

  const feeInFchs = await sWordsContract.methods
    .usdToFchs(feeInUsd)
    .call(opts);

  return feeInFchs;
}

export async function getFeeInFchsFromUsd(
  sWordsContract: SWordsAlias,
  opts: Web3JsCallOptions,
  fn: SWordsMethodsFunction
): Promise<string> {
  return getFeeInFchsFromUsdFromAnyContract(
    sWordsContract,
    sWordsContract,
    opts,
    fn
  );
}

type WithOptionalFrom<T extends { from: unknown }> = Omit<T, 'from'> & Partial<Pick<T, 'from'>>;

export async function approveFeeFromAnyContract<T extends Contract<unknown>>(
  sWordsContract: SWordsAlias,
  feeContract: T,
  fchsToken: Contracts['FchsToken'],
  from: NonNullable<Web3JsCallOptions['from']>,
  fchsRewardsAvailable: string,
  callOpts: WithOptionalFrom<Web3JsCallOptions>,
  approveOpts: WithOptionalFrom<Web3JsSendOptions>,
  fn: MethodsFunction<T>,
  { feeMultiplier, allowInGameOnlyFunds }: { feeMultiplier?: string | number, allowInGameOnlyFunds?: boolean } = {},
  fnReturnsFchs: boolean = false
) {
  const callOptsWithFrom: Web3JsCallOptions = { from, ...callOpts };
  const approveOptsWithFrom: Web3JsSendOptions = { from, ...approveOpts };

  if(allowInGameOnlyFunds === undefined) {
    allowInGameOnlyFunds = true;
  }

  let feeInFchs = new BigNumber(
    fnReturnsFchs ?
      await fn(feeContract.methods).call(callOptsWithFrom) :
      await getFeeInFchsFromUsdFromAnyContract(
        sWordsContract,
        feeContract,
        callOptsWithFrom,
        fn
      )
  );

  if(feeMultiplier !== undefined) {
    feeInFchs = feeInFchs.times(feeMultiplier);
  }

  try {
    feeInFchs = await sWordsContract.methods
      .getFchsNeededFromUserWallet(from, feeInFchs.toString(), allowInGameOnlyFunds)
      .call(callOptsWithFrom)
      .then(n => new BigNumber(n));
  }
  catch(err) {
    const paidByRewardPool = feeInFchs.lte(fchsRewardsAvailable);

    if(paidByRewardPool) {
      return null;
    }
  }

  const allowance = await fchsToken.methods
    .allowance(from, feeContract !== sWordsContract ? feeContract.options.address : sWordsContract.options.address)
    .call(callOptsWithFrom);

  if(feeInFchs.lte(allowance)) {
    return null;
  }

  return await fchsToken.methods
    .approve(feeContract !== sWordsContract ? feeContract.options.address : sWordsContract.options.address, feeInFchs.toString())
    .send(approveOptsWithFrom);
}

export async function approveFee(
  sWordsContract: SWordsAlias,
  fchsToken: Contracts['FchsToken'],
  from: NonNullable<Web3JsCallOptions['from']>,
  fchsRewardsAvailable: string,
  callOpts: WithOptionalFrom<Web3JsCallOptions>,
  approveOpts: WithOptionalFrom<Web3JsSendOptions>,
  fn: SWordsMethodsFunction,
  opts: { feeMultiplier?: string | number, allowInGameOnlyFunds?: boolean } = {}
) {
  return await approveFeeFromAnyContract(
    sWordsContract,
    sWordsContract,
    fchsToken,
    from,
    fchsRewardsAvailable,
    callOpts,
    approveOpts,
    fn,
    opts
  );
}

export async function waitUntilEvent(contract: Contract<unknown>, eventName: string, opts: Record<string, unknown>): Promise<Record<string, unknown>> {
  let subscriber: any;

  const data = await new Promise<Record<string, unknown>>((resolve, reject) => {
    subscriber = contract.events[eventName](opts, (err: Error | null, data: Record<string, unknown> | null) => {
      if(err) reject(err);
      else resolve(data!);
    });
  });

  subscriber.unsubscribe();

  return data;
}

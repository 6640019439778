









import Vue from 'vue';
import { toBN } from '@/utils/common';
import Events from '@/events';
import { mapState } from 'vuex';
import BigNumber from 'bignumber.js';

export default Vue.extend({
  props: {
    fchs: {
      type: String,
      default: '0',
    },
    fchsMinDecimals: {
      type: Number,
      default: 2,
    },
    fchsMaxDecimals: {
      type: Number,
      default: 4,
    },
    usdDecimals: {
      type: Number,
      default: 2,
    },
    showValueInFchsOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showValueInUsd: false,
    };
  },
  computed: {
    ...mapState(['fchsPriceInUsd']),
    formattedUsd(): string {
      return `$${this.calculateFchsPriceInUsd().toFixed(this.usdDecimals)}`;
    },
    formattedFchs(): string {
      return `${this.calculateFchsWithDecimals()} FCHS`;
    },
  },
  methods: {
    calculateFchsPriceInUsd(): number {
      return ((this.fchs as unknown as number) *
        this.fchsPriceInUsd) as unknown as number;
    },
    calculateFchsWithDecimals(): string {
      const parsedFchs = toBN(this.fchs);
      const decimalPlaces = this.countDecimalPlaces(parsedFchs);

      if (this.fchsMaxDecimals < decimalPlaces) {
        return `~${parsedFchs.toFixed(this.fchsMaxDecimals)}`;
      }

      if (
        decimalPlaces > this.fchsMinDecimals &&
        decimalPlaces <= this.fchsMaxDecimals
      ) {
        return parsedFchs.toString();
      }

      return parsedFchs.toFixed(this.fchsMinDecimals);
    },

    countDecimalPlaces(value: BigNumber) {
      if (Math.floor(+value.valueOf()).toString() === value.valueOf()) return 0;
      return value.toString().split('.')[1].length || 0;
    },

    checkStorage() {
      this.showValueInUsd = localStorage.getItem('showFchsInUsd') === 'true';
    },
  },
  mounted() {
    this.checkStorage();
    Events.$on('setting:showFchsInUsd', () => this.checkStorage());
  },
});






















































import BN from 'bignumber.js';
import BigButton from '../components/BigButton.vue';
import CharacterList from '../components/smart/CharacterList.vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { fromWeiEther, toBN } from '../utils/common';
import Vue from 'vue';

interface Data {
  recruitCost: string;
}

export default Vue.extend({
  computed: {
    ...mapState(['characters', 'maxStamina', 'currentCharacterId', 'defaultAccount', 'fchsBalance']),
    ...mapGetters([
      'contracts',
      'ownCharacters',
      'ownWeapons',
      'currentCharacter',
      'currentCharacterStamina',
      'getCharacterName',
      'getExchangeUrl',
    ]),

    character(): any {
      if (!this.currentCharacter) {
        return {
          id: null,
          name: '???',
          level: -1,
          experience: -1,
        };
      }

      const c = this.currentCharacter;
      return {
        id: c.id,
        name: this.getCharacterName(c.id),
        level: c.level,
        experience: c.xp,
      };
    },
  },

  async created() {
    const recruitCost = await this.contracts.SWords.methods.mintCharacterFee().call({ from: this.defaultAccount });
    const fchsRecruitCost = await this.contracts.SWords.methods.usdToFchs(recruitCost).call();
    this.recruitCost = new BN(fchsRecruitCost).div(new BN(10).pow(18)).toFixed(4);
  },

  data() {
    return {
      recruitCost: '0',
    } as Data;
  },

  methods: {
    ...mapMutations(['setCurrentCharacter']),
    ...mapActions(['mintCharacter']),

    async onMintCharacter() {
      try {
        await this.mintCharacter();
      } catch (e) {
        (this as any).$dialog.notify.error('Could not mint character: insufficient funds or transaction denied.');
      }
    },
    formatFchs() {
      return fromWeiEther(this.fchsBalance);
    },
    canRecruit() {
      const cost = toBN(this.recruitCost);
      const balance = toBN(this.fchsBalance);
      return balance.isGreaterThanOrEqualTo(cost);
    },
  },

  components: {
    BigButton,
    CharacterList,
  },
});

import { getConfigValue } from './contracts';
import { allStakeTypes, isStakeType, StakeType } from './interfaces';

let availableStakingContracts = allStakeTypes;

if(getConfigValue('VUE_APP_STAKE_TYPES_AVAILABLE')) {
  availableStakingContracts = getConfigValue('VUE_APP_STAKE_TYPES_AVAILABLE')
    .split(',')
    .filter(isStakeType);
}

export interface StakingContractEntry {
  stakingRewardsAddress: string;
  stakingTokenAddress: string;
}

export const stakingContractsInfo: Partial<Record<StakeType, Partial<StakingContractEntry>>> = {
  fchs: {
    stakingRewardsAddress: getConfigValue('VUE_APP_FCHS_STAKING_REWARDS_CONTRACT_ADDRESS'),
    stakingTokenAddress: getConfigValue('VUE_APP_FCHS_TOKEN_CONTRACT_ADDRESS')
  }
};

if(availableStakingContracts.includes('fchs2')) {
  stakingContractsInfo.fchs2 = {
    stakingRewardsAddress: getConfigValue('VUE_APP_FCHS2_STAKING_REWARDS_CONTRACT_ADDRESS'),
    stakingTokenAddress: getConfigValue('VUE_APP_FCHS2_TOKEN_CONTRACT_ADDRESS')
  };
}

if(availableStakingContracts.includes('lp')) {
  stakingContractsInfo.lp = {
    stakingRewardsAddress: getConfigValue('VUE_APP_LP_STAKING_REWARDS_CONTRACT_ADDRESS'),
    stakingTokenAddress: getConfigValue('VUE_APP_LP_TOKEN_CONTRACT_ADDRESS')
  };
}

if(availableStakingContracts.includes('lp2')) {
  stakingContractsInfo.lp2 = {
    stakingRewardsAddress: getConfigValue('VUE_APP_LP_2_STAKING_REWARDS_CONTRACT_ADDRESS'),
    stakingTokenAddress: getConfigValue('VUE_APP_LP_2_TOKEN_CONTRACT_ADDRESS')
  };
}

interface HumanReadableDetailsForStakeType {
  stakeTokenName: string;
  rewardTokenName: string;
  stakeTitle: string;
  deprecated?: boolean;
}

const defaultHumanReadableDetailsForStakeTypes: Record<StakeType, HumanReadableDetailsForStakeType> = {
  fchs: {
    stakeTokenName: 'FCHS',
    rewardTokenName: 'FCHS',
    stakeTitle: 'FCHS for FCHS (Old)',
    deprecated: true
  },
  fchs2: {
    stakeTokenName: 'FCHS',
    rewardTokenName: 'FCHS',
    stakeTitle: 'FCHS for FCHS'
  },
  lp: {
    stakeTokenName: 'FCHS-WBNB',
    rewardTokenName: 'FCHS',
    stakeTitle: 'FCHS-WBNB for FCHS'
  },
  lp2: {
    stakeTokenName: 'FCHS-BNB',
    rewardTokenName: 'FCHS',
    stakeTitle: 'FCHS-BNB for FCHS V2'
  },
};

export const humanReadableDetailsForStakeTypes = defaultHumanReadableDetailsForStakeTypes;

const stakeTypeForUnclaimedRewards = getConfigValue('VUE_APP_STAKE_TYPE_FOR_UNCLAIMED_REWARDS');

export const stakeTypeThatCanHaveUnclaimedRewardsStakedTo: StakeType =
  stakeTypeForUnclaimedRewards && isStakeType(stakeTypeForUnclaimedRewards)
    ? stakeTypeForUnclaimedRewards
    : 'fchs';

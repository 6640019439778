


























































import Vue from 'vue';
import { toBN, fromWeiEther } from '../utils/common';
import Hint from '../components/Hint.vue';
import {PropType} from 'vue/types/options';
import axios from 'axios';
import { getConfigValue } from '@/contracts';

interface CombatResult {
  isVictory: boolean;
  playerRoll: string
  enemyRoll: string;
  xpGain: string;
  fchsGain: string;
  bnbGasUsed: string;
}

export default Vue.extend({
  props: {
    fightResults: {
      type: Object as PropType<CombatResult>,
      default() {
        return {} as CombatResult;
      },
    }
  },

  data() {
    return {
      fchsPrice: 0,
      gasToken: '',
    };
  },

  computed: {
    formattedOutcome(): string {
      return `你 ${this.fightResults.isVictory ? '赢' : '输'} 了这场战斗！`;
    },
    formattedUsd(): string {
      return `$${(this.calculateFchsPriceInUsd()).toFixed(2)}`;
    },
    formattedFchs(): string {
      return `(${toBN(fromWeiEther(this.fightResults.fchsGain)).toFixed(6)} FCHS)`;
    },
    formattedFchsTooltip(): string {
      return fromWeiEther(this.fightResults.fchsGain)+' FCHS';
    },
    formattedXpGain(): string {
      return this.fightResults.xpGain + ' xp';
    }
  },

  methods: {
    async fetchPrices(): Promise<void> {
      const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=swords,binancecoin&vs_currencies=usd');
      this.fchsPrice = response.data?.swords.usd;
    },
    calculateFchsPriceInUsd(): number {
      return fromWeiEther(this.fightResults.fchsGain) as unknown as number * this.fchsPrice as unknown as number;
    }
  },

  async mounted() {
    this.gasToken = getConfigValue('currencySymbol') || 'HT';
    await this.fetchPrices();
  },

  components: {
    Hint,
  },
});

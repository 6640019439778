








export default {
  props: ['mainText', 'subText', 'disabled'],
};


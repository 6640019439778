



































































































































































































































































































































































































































import BN from 'bignumber.js';
import WeaponGrid from '../components/smart/WeaponGrid.vue';
import BigButton from '../components/BigButton.vue';
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import WeaponIcon from '../components/WeaponIcon.vue';
import { BModal } from 'bootstrap-vue';
import NftList from '@/components/smart/NftList.vue';
import { Contracts, IState } from '@/interfaces';
import { Accessors } from 'vue/types/options';
import DustBalanceDisplay from '@/components/smart/DustBalanceDisplay.vue';
import { fromWeiEther, toBN } from '@/utils/common';

type StoreMappedState = Pick<IState, 'defaultAccount'| 'ownedWeaponIds' | 'fchsBalance' | 'inGameOnlyFunds' | 'fchsRewards'>;

interface StoreMappedGetters {
  contracts: Contracts;
  ownWeapons: any[];
  nftsCount: number;
  stakedFchsBalanceThatCanBeSpent: number;
}

interface Data {
  showReforge: boolean;
  showBlacksmith: boolean,
  showDustForge: boolean,
  showReforgeDust: boolean,
  reforgeWeaponId: string | null;
  burnWeaponId: string | null;
  selectedNft: string | null;
  forgeCost: string;
  reforgeCost: string;
  dustReforgeCost: string,
  burnCost: string,
  disableForge: boolean;
  newForged: number[];
  currentListofWeapons: string[];
  selectedElement: number | null,
  chosenElementFee: number | null,
  clickedForgeButton: number | null,
  spin: boolean;
  lesserDust: string,
  greaterDust: string,
  powerfulDust: string,
  dust: string[],
  allowDustForge: false,
  burnWeaponIds: any[],
  onError: boolean;
  hideWeapons: any[];
  useStakedForForge: boolean;
  disableUseStakedForForge: boolean;
  disableX10ForgeWithStaked: boolean;
  forgeCostBN: BN;
  targetSkin: string;
  haveWeaponCosmetic1: number;
  haveWeaponCosmetic2: number;
}

export default Vue.extend({
  data() {
    return {
      showReforge: false,
      showBlacksmith: true,
      showDustForge: false,
      showReforgeDust: false,
      reforgeWeaponId: null,
      burnWeaponId: null,
      selectedNft: null,
      forgeCost: '0',
      reforgeCost: '0',
      dustReforgeCost: '0',
      burnCost: '0',
      disableForge: false,
      newForged: [],
      currentListofWeapons: [],
      selectedElement: null,
      chosenElementFee: null,
      clickedForgeButton: null,
      spin: false,
      lesserDust: '0',
      greaterDust: '0',
      powerfulDust: '0',
      dust: [],
      allowDustForge: false,
      burnWeaponIds: [],
      onError: false,
      hideWeapons: [],
      useStakedForForge:false,
      disableUseStakedForForge: false,
      disableX10ForgeWithStaked: false,
      forgeCostBN: new BN(0),
      targetSkin: '',
      haveWeaponCosmetic1: 0,
      haveWeaponCosmetic2: 0
    } as Data;
  },

  computed: {
    ...(mapState(['defaultAccount','ownedWeaponIds','ownedShieldIds','fchsBalance', 'inGameOnlyFunds', 'fchsRewards']) as Accessors<StoreMappedState>),
    ...(mapGetters([
      'contracts', 'ownWeapons', 'nftsCount', 'ownShields',
      'getPowerfulDust', 'getGreaterDust', 'getLesserDust',
      'stakedFchsBalanceThatCanBeSpent'
    ]) as Accessors<StoreMappedGetters>),

    totalFchsBalance(): BN {
      console.log(toBN(fromWeiEther(this.fchsRewards)).plus(toBN(fromWeiEther(this.inGameOnlyFunds))).plus(toBN(fromWeiEther(this.fchsBalance))).toString());
      return toBN(fromWeiEther(this.fchsRewards)).plus(toBN(fromWeiEther(this.inGameOnlyFunds))).plus(toBN(fromWeiEther(this.fchsBalance)));
    },

    disableConfirmButton(): boolean {
      return this.selectedElement === null || !this.chosenElementFee ||
        this.totalFchsBalance.lt(this.forgeCostBN.times(this.chosenElementFee).times(this.clickedForgeButton ? 10 : 1));
    }
  },

  watch: {
    reforgeWeaponId() {
      this.showReforge = false;
      this.burnWeaponId = null;
    },
    stakedFchsBalanceThatCanBeSpent(){
      const stakedFchsBalanceThatCanBeSpentBN: BN = new BN(this.stakedFchsBalanceThatCanBeSpent).div(new BN(10).pow(18));

      if((stakedFchsBalanceThatCanBeSpentBN.minus(this.forgeCostBN.multipliedBy(0.8))).isLessThan(0)) {
        this.disableUseStakedForForge = true;
      }
      if((stakedFchsBalanceThatCanBeSpentBN.minus(this.forgeCostBN.multipliedBy(0.8).multipliedBy(10))).isLessThan(0)){
        this.disableX10ForgeWithStaked = true;
      }
    }
  },

  async created() {
    if(!this.contracts.SWords) return;
    const forgeCost = await this.contracts.SWords.methods.mintWeaponFee().call({ from: this.defaultAccount });
    const fchsForgeCost = await this.contracts.SWords.methods.usdToFchs(forgeCost).call({ from: this.defaultAccount });
    this.forgeCost = new BN(fchsForgeCost).div(new BN(10).pow(18)).toFixed(4);
    const stakedFchsBalanceThatCanBeSpentBN: BN = new BN(this.stakedFchsBalanceThatCanBeSpent).div(new BN(10).pow(18));
    this.forgeCostBN = new BN(fchsForgeCost).div(new BN(10).pow(18));

    if((stakedFchsBalanceThatCanBeSpentBN.minus(this.forgeCostBN.multipliedBy(0.8))).isLessThan(0)) {
      this.disableUseStakedForForge = true;
    }
    if((stakedFchsBalanceThatCanBeSpentBN.minus(this.forgeCostBN.multipliedBy(0.8).multipliedBy(10))).isLessThan(0)){
      this.disableX10ForgeWithStaked = true;
    }
    const reforgeCost = await this.contracts.SWords.methods.reforgeWeaponFee().call({ from: this.defaultAccount });
    const fchsReforgeCost = await this.contracts.SWords.methods.usdToFchs(reforgeCost).call({ from: this.defaultAccount });
    this.reforgeCost = new BN(fchsReforgeCost).div(new BN(10).pow(18)).toFixed(4);

    const reforgeDustCost = await this.contracts.SWords.methods.reforgeWeaponWithDustFee().call({ from: this.defaultAccount });
    const fchsDustReforgeCost = await this.contracts.SWords.methods.usdToFchs(reforgeDustCost).call({ from: this.defaultAccount });
    this.dustReforgeCost = new BN(fchsDustReforgeCost).div(new BN(10).pow(18)).toFixed(4);

    const burnCost = await this.contracts.SWords.methods.burnWeaponFee().call({ from: this.defaultAccount });
    const fchsBurnCost = await this.contracts.SWords.methods.usdToFchs(burnCost).call({ from: this.defaultAccount });
    this.burnCost = new BN(fchsBurnCost).div(new BN(10).pow(18)).toFixed(4);
  },

  methods: {
    ...mapActions(['mintWeapon', 'reforgeWeapon', 'mintWeaponN',
      'burnWeapon', 'reforgeWeaponWithDust', 'massBurnWeapons']),

    toggleCheckbox() {
      this.useStakedForForge = !this.useStakedForForge;
      if (this.useStakedForForge) localStorage.setItem('useStakedForForge', 'true');
      else localStorage.setItem('useStakedForForge', 'false');
    },
    async onForgeWeapon() {
      if(this.disableForge) return;

      (this.$refs['forge-element-selector-modal']as BModal).hide();

      const forgeMultiplier = 1;

      this.disableForge = true;
      // Incase the network or mm are having issues, after 1 min we reshow
      const failbackTimeout = setTimeout(() => {
        this.disableForge = false;
      }, 30000);

      try {
        await this.mintWeapon({ useStakedFchsOnly: this.useStakedForForge, chosenElement: this.selectedElement });

      } catch (e) {
        console.error(e);
        (this as any).$dialog.notify.error('Could not forge sword: Insuffucient funds or transaction was denied.');
      } finally {
        clearTimeout(failbackTimeout);
        this.disableForge = false;
        this.selectedElement = null;
      }
      this.relayFunction(forgeMultiplier);
    },

    async onForgeWeaponx10(){
      if(this.disableForge) return;

      (this.$refs['forge-element-selector-modal']as BModal).hide();

      this.disableForge = true;
      const forgeMultiplier = 10;

      // Incase the network or mm are having issues, after 1 min we reshow
      const failbackTimeout = setTimeout(() => {
        this.disableForge = false;
      }, 30000);

      try {
        await await this.mintWeaponN({ num: forgeMultiplier, useStakedFchsOnly: this.useStakedForForge, chosenElement: this.selectedElement });

      } catch (e) {
        console.error(e);
        (this as any).$dialog.notify.error('Could not forge sword: Insuffucient funds or transaction was denied.');
      } finally {
        clearTimeout(failbackTimeout);
        this.disableForge = false;
        this.selectedElement = null;
      }
      this.relayFunction(forgeMultiplier);

    },

    relayFunction(offset: number){
      try{
        this.viewNewWeapons(offset);
      } catch (e) {
        console.error(e);
        this.onError = true;
      }
    },

    onShowForgeDetails() {
      (this.$refs['forge-details-modal'] as BModal).show();
    },

    onClickForge(i: number) {
      this.clickedForgeButton = i;
      this.chosenElementFee = null;
      (this.$refs['forge-element-selector-modal']as BModal).show();
    },

    setChosenElement(ele: any, i: number) {
      this.selectedElement = i;
      this.chosenElementFee = i === 100 ? 1 : 2;
      ele.srcElement.classList.toggle('done');
      Array.from(ele.srcElement.parentNode.childNodes).forEach((child: any) => {
        if (child !== ele.srcElement && child.classList.contains('done') === true){
          child.classList.toggle('done');
        }
      });
    },

    showReforgeConfirmation() {
      (this.$refs['reforge-confirmation-modal'] as BModal).show();
    },

    showDustReforgeConfirmation() {
      (this.$refs['dustreforge-confirmation-modal'] as BModal).show();
    },

    showMassDustConfirmation() {
      (this.$refs['mass-dust-confirmation-modal'] as BModal).show();
    },

    displayDustReforge() {
      this.showReforge = true;
      this.showBlacksmith = false;
      this.showReforgeDust = true;
      this.showDustForge = false;
      this.lesserDust = '0';
      this.greaterDust = '0';
      this.powerfulDust = '0';
    },
    displayDustCreation(){
      return this.showReforge = true,
      this.showBlacksmith = false,
      this.showDustForge = true,
      this.showReforgeDust = false,
      this.hideWeapons = this.ownedWeaponIds;
    },
    displayBlacksmith(){
      this.showReforge = false;
      this.showBlacksmith = true;
      this.showDustForge = false;
      this.showReforgeDust = false;
    },
    cancelReforge() {
      this.showReforge = false;
      this.showBlacksmith = true;
      this.showDustForge = false;
      this.showReforgeDust = false;
      this.burnWeaponIds = [];
      this.hideWeapons = this.ownedWeaponIds;
      this.lesserDust = '0';
      this.greaterDust = '0';
      this.powerfulDust = '0';
    },
    clearAllMassBurn(){
      return this.burnWeaponIds = [],
      this.hideWeapons = this.ownedWeaponIds;
    },
    getWeaponToUpgrade() {
      return this.ownWeapons.find(x => x.id === this.reforgeWeaponId);
    },

    addBurnWeapon(id: number){
      this.burnWeaponIds.push(id.toString());
      this.hideWeapons = this.hideWeapons.filter(val => !this.burnWeaponIds.includes(val));
      this.burnWeaponId = null;
    },

    removeBurnWeapon(id: number){
      this.hideWeapons.push(id.toString());
      this.burnWeaponIds = this.burnWeaponIds.filter(x => x !== id.toString());
    },

    viewNewWeapons(offset: number){
      this.newForged = [];
      this.ownedWeaponIds.forEach(x => {
        this.newForged.push(x);
      });

      this.newForged.splice(0, this.ownedWeaponIds.length - offset + 1);


      // eslint-disable-next-line no-constant-condition
      if (this.newForged.length > 0 && !this.onError){
        this.spin = true;
        (this.$refs['new-weapons'] as BModal).show();

        setTimeout(() => {
          this.spin = false;
        }, 10000);
      }

    },

    async onReforgeWeaponWithDust() {
      try {
        await this.reforgeWeaponWithDust({
          reforgeWeaponId: this.reforgeWeaponId,
          lesserDust: this.lesserDust,
          greaterDust: this.greaterDust,
          powerfulDust:this.powerfulDust
        });

        this.lesserDust = '0';
        this.greaterDust = '0';
        this.powerfulDust = '0';

      } catch (e) {
        console.error(e);
        (this as any).$dialog.notify.error('Could not reforge sword: Insufficient funds, Dust, or transaction was denied.');
      }
    },

    async onMassBurnWeapons() {
      try {
        await this.massBurnWeapons({
          burnWeaponIds: this.burnWeaponIds,
        });
        this.burnWeaponIds = [];
        this.burnWeaponId = null;
      } catch (e) {
        console.error(e);
        (this as any).$dialog.notify.error('Could not burn sword: Insufficient funds or transaction was denied.');
      }
    },
  },

  components: {
    DustBalanceDisplay,
    WeaponGrid,
    BigButton,
    WeaponIcon,
    BModal,
    NftList,
  },
});
